import React, { useState, useRef, useEffect } from "react";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";
import "../../components/navbar/navbar.css";

const path = window.location.pathname
const Navbar = () => {

  const [show, setShow] = React.useState();

  return (
    <div className="">
      <header className='header'>
        <div className="container">
          <div className="nav">
         
            <a className="logo" href="/">  <img src={require('../../assets/images/G.png')} alt="" width='50px' height='35px' /> BTG <span>TECHNOLOGIES </span></a>
            <button className="ms-auto toggle-btn" onClick={() => setShow(!show)}><i class="fa fa-bars" aria-hidden="true"></i></button>
            <ul className={show ? "ms-auto show" : "ms-auto"}>
              <li><a className={path==='/'?"active":""}  href="/">HOME</a></li>
              <li><a className={path==='/about'?"active":""} href="/about">ABOUT US</a></li>
              <li><a className={path==='/services'?"active":""} href="/services">SERVICES</a></li>
              <li><a className={path==='/pricing'?"active":""} href="/pricing">PACKAGES</a></li>
              {/* <li><a className={path==='/testimonials'?"active":""} href="/testimonials">TESTIMONIALS</a></li> */}
              <li><a className={path==='/contact'?"active":""} href="/contact">CONTACT US</a></li>
              <li className="cta-li">
                <a href="javascript:void(Tawk_API.toggle())" className="btn">LIVE CHAT</a>
              </li>
              <li className="cta-li">
                <a href="tel:+18024328025" className="btn danger">CALL US NOW</a>
              </li>

            </ul>
          </div>
        </div>
      </header>

    </div>


  )

}



export default Navbar