import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../About/About.css";
import Banner from "../../components/banner/banner";
import Testimonials from "../../components/testimonials/testimonials";
import Feature from "../../components/feature/feature";
import Technologies from "../../components/technologies/technologies";

const About = () => {


  return (
  <div className="main">

    <Banner 
    heading={<h1>About <span>US</span></h1>} 
    description={<p>The Only Leading Marketing Agency Who Knows the Art of Marketing, the Science of Sales! Grab The Ample Opportunity To Convert Your CUSTOMERS NOW!</p>}
    button={<a href="#contactUs" className="btn">Explore More</a>}
    />

    <div className="achievements">
      <div className="container">
        <Marquee gradient={false} width="100%" speed={50} direction="left">
          <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
          <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
          <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
        </Marquee>
      </div>
    </div>

    <div className="about-sec">
      <div className="container">
        <h2><span>Who</span> Are We?</h2>
        <p className="sub-pera">Bridge The Gap (BTG) Technologies Bring Ideas To Reality By Using Transcendent Technology</p>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <p>Bridge The Gap (BTG) Technologies is a US-based Tech Company aiming to be the preferred IT Solutions Partner, thriving on creative solutions and products while utilizing technology and service excellence.</p>
            <p>We combine technology and human skills to create a perfect mix for meeting your business needs. Consider us a partner in your log design, web development, branding, social media marketing, and mobile app development projects with the industry's best team satisfaction, independent analyst recognition, and an ever-growing list of satisfied clients who work with us again and again. Bridge The Gap (BTG) Technologies brings decades of knowledge to the table to meet agreed-upon service levels.</p>
            <div className="btn-area">
              <a href="#contactUs" className="btn">Start your project</a>
              <a href="javascript:void(Tawk_API.toggle())" className="btn danger">Live chat</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Feature />

    <div className="stats">
      <div className="row gx-0">
        {/* <div className="col-md-3">
          <div className="box">
            <h3>$750M+</h3>
            <p>Customer Revenue Growth</p>
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="box">
            <h3>300+</h3>
            <p>Currennt Customer</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <h3>900+</h3>
            <p>Our TALENTED TEAM</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="box">
            <h3>20K</h3>
            <p>Clients Happy</p>
          </div>
        </div>
      </div>
    </div>

    <div className="process">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <h2>Our <span>Work</span> Process</h2>
              <h3>Sparking Ideas</h3>
              <p>We kick off our work process by brainstorming ideas. We mix and match concepts, shake things up like a cocktail, and let our creative juices flow like a wild river. From design inspirations to marketing strategies, we'll set your project ablaze with our fiery ideas.</p>
              <h3>Crafting Magic</h3>
              <p>Once we've captured the essence of your vision, we embark on a journey of craftsmanship. We meticulously sculpt designs, code websites with finesse, and create marketing campaigns that hit the bullseye.</p>
              <h3>Collaboration Extraordinaire</h3>
              <p>We foster a collaborative environment where your dreams take center stage. We listen to your desires, understand your needs, and twirl our expertise to deliver results. From start to finish, we will be your partners on this dance floor, creating a harmonious masterpiece together.</p>
              <h3>Sizzling Success</h3>
              <p>Our work process isn't complete until we've set the world ablaze with your project. We launch your designs, websites, and marketing campaigns like fireworks on a starry night. We aim to achieve sizzling success that leaves your competitors in the dust. So, let's set the world ablaze together!</p>
            </div>
            <div className="col-lg-7 offset-lg-1 col-md-7">
              <img className="side-img" src={require('../../assets/images/home/process/process-img.png')} alt="" />
            </div>
          </div>
        </div>
      </div>

    <Technologies />

    <Testimonials />

  </div>

    
  )
}

export default About