import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import Slider from "react-slick";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../Pricing/Pricing.css";
import Banner from "../../components/banner/banner";
import FAQ from "../../components/faq/faq";
import Pricings from "../../components/pricings/pricings";






const Pricing = () => {
    



    // ServiceSlider
    var ServiceSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    // testimonialSlider
    var testimonialSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    vertical: false
                }
            }
        ]
    };

    return (
        <div className="main">

            <Banner 
            heading={<h1>Pricing That <br /><span>Makes Sense</span></h1>} 
            description={<p>Bridge The Gap (BTG) Technologies prices its packages at very sensible rates to help businesses save their hard-earned money from going down the drain.</p>}
            button={<a href="#price" className="btn">Explore More</a>}
             />

            <div className="achievements">
                <div className="container">
                    <Marquee gradient={false} width="100%" speed={50} direction="left">
                        <img src={require('../../assets/images/home/badges/b1.png')} alt="" />
                        <img src={require('../../assets/images/home/badges/b2.png')} alt="" />
                        <img src={require('../../assets/images/home/badges/b3.png')} alt="" />
                        <img src={require('../../assets/images/home/badges/b4.webp')} alt="" />
                        <img src={require('../../assets/images/home/badges/b5.webp')} alt="" />
                        <img src={require('../../assets/images/home/badges/b6.png')} alt="" />
                        <img src={require('../../assets/images/home/badges/b7.png')} alt="" />
                        <img src={require('../../assets/images/home/badges/b8.png')} alt="" />
                    </Marquee>
                </div>
            </div>

            <Pricings />

            <FAQ />

        </div>


    )
}

export default Pricing