import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

const Banner = ({ heading, description, button }) => {


  const [showMessage, setshowMessage] = useState("");
  const [error, seterror] = useState("");
  const [search, setSearch] = useState(null)
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [message, setmessage] = useState("");


  // Formik Form validations
  const HandleFormValidation = useFormik({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("This field is required"),
      mobile_no: Yup.string().required("Mobile number is required"),
    }),
    initialValues: {
      first_name: "",
      email: "",
      mobile_no: "",
      message: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("done")
      const body = {
        name: `${values.first_name} `,
        email: values.email,
        phoneno: values.mobile_no,
        message: values.message,
        service: "GFS IT Solutions",
      };
      axios
        .post("https://mail-api.gfsitsolutions.com/", body)
        .then((response) => {
          setshowMessage(response.data);
          resetForm();
          setSubmitting(false);
          setTimeout(() => {
            setshowMessage("");
          }, 3000);
        })
        .catch((error) => {
          seterror(error);
          setSubmitting(false);
          setTimeout(() => {
            seterror("");
          }, 3000);
        });
      console.log(values);
    },
  });


  const isFormikValid = (name) =>
    !!(HandleFormValidation.touched[name] && HandleFormValidation.errors[name]);
  const getFormMessage = (name) => {
    return (
      isFormikValid(name) && (
        <p
          className="small m-0"
          style={{ color: "red", fontSize: "12px", paddingLeft: '10px' }}
        >
          {HandleFormValidation.errors[name]}
        </p>
      )
    );
  };
  return (
    <div className="">
      <div className="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="text-area">
                {heading}
                {description}
                {button}
              </div>
            </div>
            <div className="col-xl-4 offset-xl-2 col-lg-5 offset-lg-1 col-md-6">
              <div className="form-area">
                <div className="box">
                  <div className="offer">
                    <p>limited time offer<br />avail <span>70%</span> discount now</p>
                  </div>
                  <form onSubmit={HandleFormValidation.handleSubmit}>
                    <div className="form-group">
                      <label>Full Name</label>
                      <input onChange={HandleFormValidation.handleChange} name="first_name"
                        value={HandleFormValidation.values.first_name} type="text" className="form-control" />

                    </div>
                    {getFormMessage("first_name")}
                    <div className="form-group">
                      <label>Email Address</label>
                      <input onChange={HandleFormValidation.handleChange} name="email"
                        value={HandleFormValidation.values.email} type="text" className="form-control" />
                    </div>
                    {getFormMessage("email")}
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input onChange={HandleFormValidation.handleChange} name="mobile_no"
                        value={HandleFormValidation.values.mobile_no} type="number" className="form-control" />
                    </div>
                    {getFormMessage("mobile_no")}
                    <div className="form-group">
                      <label>Your Message</label>
                      <textarea onChange={HandleFormValidation.handleChange} name="message"
                        value={HandleFormValidation.values.message} id="" className="form-control"></textarea>
                    </div>
                    {getFormMessage("message")}
                    {error?.message === "Request failed with status code 400"
                    ? <p style={{fontSize: '13px',textAlign:'center',color:"#DF0003",fontWeight:'600',fontSize:"20px"}}>There was an error submitting the form.</p> : ""}
                    
                    <p style={{fontSize: '13px',color:'green',fontWeight:600,textAlign:'center'}}>{showMessage.message}</p>
                    <div className="send-btn">
                    </div>
                    <button type="submit"  className="submit-btn">Submit Now</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  )

}



export default Banner