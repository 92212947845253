import React, { useState, useRef, useEffect } from "react";
import Marquee from "react-fast-marquee";
import "../../assets/css/style.css";
import "../Home/Home.css";
import "../SidePages/SidePages.css";

const PrivacyPolicy = () => {

  return (
  <div className="main">

    <div class="outer-content">
      <div className="container">
        <div class="content-privacy">
          <h2>Privacy Policy</h2>
          <p>At Bridge The Gap (BTG) Technologies, accessible from <a href="https://btgtechnologies.com/">https://btgtechnologies.com/</a> , one of our main priorities is the privacy of our visitors/clients. This Privacy Policy document contains types of information collected and recorded by Bridge The Gap (BTG) Technologies and how we process it. Don't hesitate to contact us if you have questions or require more information about our Privacy Policy. This Privacy Policy applies only to our online activities. It is effective for visitors to our website concerning the information they share and collects in <b>Bridge The Gap (BTG) Technologies</b>. This policy does not apply to any information collected offline or via channels other than this website.</p>
          <h3>Consent</h3>
          <p>By using our website, you, as a result of this, consent to our Privacy Policy and agree to its terms.</p>
          <h3>What Information do We Collect?</h3>
          <p>The personal information you are asked/required to provide and why you are asked to provide it will be made clear when we ask you to provide your personal information. If you contact us directly, we may receive added information about you, such as your name, email address, phone number, the contents of the message and attachments you may send us, and any other information you may choose to provide. When you register for an Account, we may ask for your contact information, including items such as:</p>
          <ul>
            <li>Name</li>
            <li>Company Name</li>
            <li>Address</li>
            <li>Email Address</li>
            <li>Telephone number</li>
          </ul>
          <h3>How Do We Use Your Information?</h3>
          <p> We use the information we collect in several ways, including to:</p>
          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Advance, personalize, and expand our website</li>
            <li>Understand and examine how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Communicate with you, either directly or through one of our associates, including for customer service, to provide you with updates and other information relating to the website and for advertising and promotional purposes</li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <h3>Log Files</h3>
          <p>
            <b>Bridge The Gap (BTG) Technologies</b> follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting firms do this as part of hosting services' analytics. The information collected by log files includes:
          </p>
          <ul>
            <li>Internet Protocol (IP) addresses</li>
            <li>Browser Type</li>
            <li>Internet Service Provider (ISP)</li>
            <li>Date and time stamp</li>
            <li>Referring/exit pages</li>
            <li>The number of clicks</li>
          </ul>
          <p>These are not linked to any personally distinguishable information. The data aims to examine trends, manage the site, track users' activities on the website, and gather demographic information.</p>
          <h3>Cookies and Web Beacons</h3>
          <p>Like any other website, <b>Bridge The Gap (BTG) Technologies</b> uses "cookies." These cookies store information, including visitors' preferences and the pages on the website that the visitor opened or visited. The data enhances the users' experience by customizing our web page content based on the visitor's browser type and other information. </p>
          <h3>Advertising Partners Privacy Policies</h3>
          <p>You may refer to this list to find the Privacy Policy for each Bridge The Gap (BTG) Technologies advertising partner. Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons used in their respective ads and links that appear on Bridge The Gap (BTG) Technologies, which are sent directly to users' browsers. They automatically receive your IP address when this happens. These technologies are used to measure the effectiveness of their marketing campaigns and personalize the advertising content you see on websites you visit. <em>Note that Bridge The Gap (BTG) Technologies has no access to or control over these cookies used by third-party advertisers.</em></p>
          <h3>Third-Party Privacy Policies</h3>
          <p><b>Bridge The Gap (BTG) Technologies</b> Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt out of specific options. You can select to disable cookies through your browser options. To know more detailed information about cookie administration with specific web browsers, it can be found on the browsers' respective websites.
          </p>
          <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
          <p>Under the CCPA, among other rights, California consumers have the right to:</p>
          <ol>
            <li>Request that a business that collects a consumer's data disclose the categories and specific pieces of personal data that a company has gathered about consumers.</li>
            <li>Request that a business delete any personal data about the consumer a company has collected.</li>
            <li>Request that a business that sells a consumer's data not sell it.</li>
          </ol>
          <p>If you make a request, we have 30 days to respond. Please get in touch with us if you would like to exercise any of these rights. </p>
          <h3>GDPR Data Protection Rights</h3>
          <p>We want to ensure you are fully mindful of your data protection rights. Every user is entitled to the following:</p>
          <h3>The Right to Access</h3>
          <p>You have the right to demand copies of your data. We may charge you a small fee for this service.</p>
          <h3>The Right to Rectification</h3>
          <p>You have the right to request that we correct any inaccurate information. You also have the right to request that we complete the information you believe is partial.</p>
          <h3>The Right to Erasure</h3>
          <p>Under certain conditions, you have the right to request to erase your personal data.</p>
          <h3>The Right to Restrict Processing</h3>
          <p>You have the right to request to restrict the processing of your data under certain conditions. The Right to Object to Processing Under certain conditions, you have the right to object to our processing of your personal data. </p>
          <h3>The Right to Data Portability</h3>
          <p>You have the right to request to transfer the data we collected to another organization, or directly to you, under certain conditions. If you make a request, we have 30 days to respond. Please contact us if you would like to exercise any of these rights. </p>
          <h3>Children's Information</h3>
          <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, monitor, and guide their online activity. <b>Bridge The Gap (BTG) Technologies</b> does not knowingly gather any Personal Identifiable Information from children under 13. Suppose your child provided this kind of information on our website. In that case, we strongly encourage you to contact us immediately, and we will try our best to remove such information from our database promptly.</p>
          <h3>Changes to This Privacy Policy</h3>
          <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
          <h3>Contact Us</h3>
          <p>If you have any questions or suggestions about our Privacy Policy, please get in touch with us at <a href="tel:+10000000000">+1 000 000 0000</a>. </p>
        </div>
      </div>
    </div>

  </div>

    
  )
}

export default PrivacyPolicy