import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../src/assets/css/style.css";
import "../../pages/Home/Home";

const Footer = () => {

  const [showMessage, setshowMessage] = useState("");
  const [error, seterror] = useState("");
  const [search, setSearch] = useState(null)
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phoneno, setphoneno] = useState("");
  const [message, setmessage] = useState("");


  // Formik Form validations
  const HandleFormValidation = useFormik({
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("This field is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("This field is required"),
      mobile_no: Yup.string().required("Mobile number is required"),
    }),
    initialValues: {
      first_name: "",
      email: "",
      mobile_no: "",
      message: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log("done")
      const body = {
        name: `${values.first_name} `,
        email: values.email,
        phoneno: values.mobile_no,
        message: values.message,
        service: "Bridge The Gap (BTG) Technologies | Branding",
      };
      axios
        .post("https://mailapi.btgtechnologies.com/", body)
        .then((response) => {
          setshowMessage(response.data);
          resetForm();
          setSubmitting(false);
          setTimeout(() => {
            setshowMessage("");
          }, 3000);
        })
        .catch((error) => {
          seterror(error);
          setSubmitting(false);
          setTimeout(() => {
            seterror("");
          }, 3000);
        });
      console.log(values);
    },
  });

  const isFormikValid = (name) =>
    !!(HandleFormValidation.touched[name] && HandleFormValidation.errors[name]);
  const getFormMessage = (name) => {
    return (
      isFormikValid(name) && (
        <p
          className="small m-0"
          style={{ color: "red", fontSize: "12px", paddingLeft: '10px' }}
        >
          {HandleFormValidation.errors[name]}
        </p>
      )
    );
  };

  return (
    <div className="">
      <div className="contact-sec" id="contactUs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h2><span>Bridge The Gap (BTG) Technologies</span> Is The Only Name That Guarantees The <span>Smoothest Launch</span> Of Your Business!</h2>
                <p>Break the ice, and smash your marketing goals! If you have any business ideas and want to transform them into the most approached brand of all times, let us burn the midnight oil for you. All it takes is a single call, and Voilà, you are already halfway to your success.</p>
                <div className="btns-area">
                  <a href="javascript:void(Tawk_API.toggle())" className="btn">LIVE CHAT</a>
                  <a href="tel:+18024328025" className="btn danger">CALL US NOW</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <form onSubmit={HandleFormValidation.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Full Name</label>
                      <input onChange={HandleFormValidation.handleChange} name="first_name"
                        value={HandleFormValidation.values.first_name} className="form-control" type="text"  />
                    </div>
                    {getFormMessage("first_name")}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Phone Number</label>
                      <input onChange={HandleFormValidation.handleChange} name="mobile_no"
                        value={HandleFormValidation.values.mobile_no} className="form-control" type="number"  />
                    </div>
                    {getFormMessage("mobile_no")}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Email Address</label>
                      <input onChange={HandleFormValidation.handleChange} name="email"
                        value={HandleFormValidation.values.email} className="form-control" type="text"  />
                    </div>
                    {getFormMessage("email")}
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">Company Name</label>
                      <input className="form-control" type="text"  />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Project Brief</label>
                      <textarea onChange={HandleFormValidation.handleChange} name="message"
                        value={HandleFormValidation.values.message} className="form-control" id=""></textarea>
                    </div>
                    {getFormMessage("message")}
                  </div>
                  <div className="col-md-12">
                  {error?.message === "Request failed with status code 400"
                    ? <p style={{fontSize: '13px',textAlign:'center',color:"#DF0003",fontWeight:'600',fontSize:"20px"}}>There was an error submitting the form.</p> : ""}
                    
                    <p style={{fontSize: '13px',color:'green',fontWeight:600,textAlign:'left', margin:0}}>{showMessage.message}</p>
                    <div className="send-btn">
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button type="submit" className="submit-btn">Submit Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <footer>
        <div className="footer-links">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-6">
                <h3>About</h3>
                <h2 className="footer-logo">Bridge The Gap (BTG) Technologies</h2>
                <p className="extra-p">Bridge The Gap (BTG) Technologies is a US-based Tech Company aiming to be the preferred IT Solutions Partner, thriving on creative solutions and products while utilizing technology and service excellence.</p>
                <a className="phone" href="tel:+10000000000">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span> +1 000 000-0000</span>
                </a>
                <br />
                <a className="phone" href="https://www.google.com/maps/place/5642+S+Park+Ave,+Morton+Grove,+IL+60053,+USA/@42.0374629,-87.7688055,17z/data=!3m1!4b1!4m6!3m5!1s0x880fcf4832f326a3:0x3e74c7f6fdb01039!8m2!3d42.0374629!4d-87.7688055!16s%2Fg%2F11c1cp0ygp?entry=ttu" target="_blank">
                <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span> 1234 A Tower Avenue <br />New Street NY 191919. USA</span>
                </a>
                <br />
                <img className="payment-img" src={require('../../assets/images/home/footer/payment-img.png')} alt="" />
              </div>
              <div className="col-lg-3 col-md-6">
                <h3>Company</h3>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about">ABOUT US</a></li>
                  <li><a href="/services">SERVICES</a></li>
                  <li><a href="/testimonials">CLIENTS REVIEW</a></li>
                  <li><a href="/contact">CONTACT US</a></li>
                  <li><a href="/privacy-policy">PRIVACY POLICY</a></li>
                  <li><a href="/terms-condition">TERMS & CONDITION</a></li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-6">
                <h3>Newsletter</h3>
                <h4>SUBSCRIBE FOR OUR NEWSLETTER</h4>
                <p>Keep up with the newest trends and tech solutions for your company's success by subscribing to our newsletter.</p>
                <form>
                  <div className="form-group">
                    <input className="form-control" type="text" placeholder="Email Address" />
                    <button type="button" className="submit-btn"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </form>
                <div className="social">
                  <h4>Follow Us</h4>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s1.png')} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s2.png')} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s3.png')} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/" target="_blank">
                        <img className="payment-img" src={require('../../assets/images/home/footer/s4.png')} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <p>Copyright &copy; 2023 Bridge The Gap (BTG) Technologies. All Rights Reserved</p>
          </div>
        </div>
      </footer>

    </div>


  )

}



export default Footer