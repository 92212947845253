import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Navbar from "./components/navbar/navbar";
import Banner from "./components/banner/banner";
import Footer from "./components/footer/footer";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Testimonials from './pages/Testimonials/Testimonials';
import Pricing from './pages/Pricing/Pricing';
import Contact from './pages/Contact/Contact';
import TermsOfCondition from './pages/SidePages/TermsCondition';
import PrivacyPolicy from './pages/SidePages/PrivacyPolicy';
import React, { Suspense, useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {


  const [scrollPosition, setScrollPosition] = useState(0);

 
  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <BrowserRouter>
    <Navbar />
    <a href="#contactUs" className={scrollPosition>=500 ? 'side-btn active' : 'side-btn'}>Contact Us</a>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms-condition' element={<TermsOfCondition />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
